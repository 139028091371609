import React from "react";
import parse, { domToReact } from "html-react-parser";
import { Link } from "gatsby";

import { Prose } from "./Prose";
import { WagtailEmbed } from "./Wagtail";

export const InternalLink = (props) => (
  <Link className="mt-5 text-base text-blue-900 hover:underline" {...props} />
);
export const ExternalLink = (props) => (
  <a className="mt-5 text-base text-blue-900 hover:underline" {...props} />
);

export const RichText = ({ html, images }) => {
  if (!html) return null;
  const options = {
    replace: (domNode) => {
      if (
        domNode.type === "tag" &&
        domNode.name === "embed" &&
        domNode.attribs?.embedtype === "image"
      ) {
        const image = images?.[domNode.attribs.id];
        return <img src={image?.src} />;
      }
      if (domNode.type === "tag" && domNode.name === "embed") {
        return <WagtailEmbed block={{ value: domNode.attribs.url }} />;
      }
      if (domNode.type === "tag" && domNode.name === "a") {
        if (domNode.attribs.linktype === "page") {
          return (
            <InternalLink to={`/${domNode.attribs["data-page-slug"]}`}>
              {domToReact(domNode.children, options)}
            </InternalLink>
          );
        } else {
          return (
            <ExternalLink href="/" {...domNode.attribs}>
              {domToReact(domNode.children, options)}
            </ExternalLink>
          );
        }
      }
    },
    htmlparser2: {
      lowerCaseTags: false,
    },
  };

  const parsed = parse(html, options);

  return <>{parsed}</>;
};

export const ProseRichText = ({ html }) => (
  <Prose>
    <RichText html={html} />
  </Prose>
);
