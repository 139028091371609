import React from "react";
import JSON5 from "json5";
import urljoin from "url-join";
import { graphql } from "gatsby";
import { gql } from "@apollo/client";
import loadable from "@loadable/component";
import * as Accordion from "@radix-ui/react-accordion";

import { RichText } from "./RichText";
import { Secondary, Primary } from "./Buttons";
import { Prose } from "./Prose";
import { Image } from "./Image";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { CMS_URL } from "../config";

const Embed = loadable(() => import("./Embed"));

export const parsePython = (s) =>
  JSON5.parse(
    s
      .replace(/False/g, "false")
      .replace(/True/g, "true")
      .replace(/None/g, "null")
  );

export const WagtailTable = ({ block }) => {
  const data = parsePython(block.value);
  const [thead, ...tbody] = data.data;

  return (
    <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
      <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-100">
        <table className="min-w-full">
          <thead>
            <tr>
              {thead.map((th) => (
                <th
                  key={th}
                  className="px-6 py-3 border-b border-gray-100 bg-gray-100 text-left text-xs leading-4 font-medium  uppercase tracking-wider"
                >
                  {th}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tbody.map((tr, i) => (
              <tr key={i} className="bg-white">
                {tr.map((td, j) => (
                  <td
                    key={`${i}-${j}`}
                    className="px-6 py-4 whitespace-nowrap text-sm leading-5 "
                  >
                    {td}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export const WagtailCTA = ({ block, ctas }) => {
  let cta, text, primary, inline;

  if (block.blocks) {
    cta = ctas[(block.blocks[0] || {}).rawValue];
    text = (block.blocks[1] || {}).rawValue;
    primary = JSON.parse((block.blocks[2] || {}).rawValue);
    inline = JSON.parse((block.blocks[3] || {}).rawValue);
  } else {
    cta = ctas[block?.value?.cta];
    text = block.value.text;
    primary = JSON.parse(block.value.primary);
    inline = JSON.parse(block.value.inline);
  }

  const Button = primary ? Primary : Secondary;

  return (
    <div className={inline ? "inline-block mr-3" : "block mb-3"}>
      <Button
        url={
          cta?.url ||
          urljoin(
            CMS_URL,
            cta?.document?.file?.split("/").join(`/${cta?.document?.id}/`)
          )
        }
      >
        {text || cta?.name}
      </Button>
    </div>
  );
};

export const WagtailText = ({ block, images }) => {
  return (
    <div>
      <RichText html={block.value} images={images} />
    </div>
  );
};
export const WagtailFaq = ({ block, images }) => {
  const pattern = /<h3>(.*?)<\/h3>([\s\S]*?)(?=<h3>|$)/g;
  let matches;
  let items = [];
  while ((matches = pattern.exec(block.value))) {
    items.push({ title: matches[1], text: matches[2].trim() });
  }

  return (
    <Accordion.Root type={"single"} collapsible>
      <>
        {items?.map((item: any, id: number) => (
          <Accordion.Item
            key={id}
            value={`${id}`}
            className="group col-span-full"
          >
            <Accordion.Header asChild>
              <Accordion.Trigger asChild>
                <button className="flex px-6 py-3 place-items-center border border-navy-100 bg-navy-50 text-navy-700 hover:bg-navy-100 hover:border-navy-100 focus:bg-navy-100 focus:border-navy-100 focus:ring-navy-800 font-jaf text-left group-radix-state-open:bg-navy-200 dark:group-radix-state-open:bg-base-900 w-full justify-between whitespace-normal !rounded-none">
                  <span>{item.title}</span>
                  <ChevronDownIcon className="group-radix-state-open:rotate-180 group-radix-state-open:text-navy-600 ml-2 h-5 w-5 transition-transform flex-shrink-0" />
                </button>
              </Accordion.Trigger>
            </Accordion.Header>
            <Accordion.Content>
              <div className="bg-navy-100 prose dark:prose-dark p-6">
                {item?.text ? (
                  <RichText html={item.text} images={images} />
                ) : null}
              </div>
            </Accordion.Content>
          </Accordion.Item>
        ))}
      </>
    </Accordion.Root>
  );
};

export const WagtailImage = ({ block, images }) => {
  let image, caption, framed;

  if (block.blocks) {
    image = (block.blocks[0] || {}).image;
    caption = (block.blocks[1] || {}).rawValue;
    framed = (block.blocks[2] || {}).value;
  } else {
    image = images[block?.value?.image];
    caption = block.value.caption;
    framed = block.value.framed;
  }

  return (
    <div className="w-full flex items-center justify-center">
      <figure className="overflow-hidden max-w-lg flex-column">
        <Image
          className={`w-full overflow-hidden ${
            framed
              ? "focus:ring rounded-lg shadow-lg object-cover"
              : "object-contain"
          }`}
          src={image?.src}
          alt={image?.title}
          width={400}
        />
        {caption && (
          <figcaption className="px-3 text-justify">{caption}</figcaption>
        )}
      </figure>
    </div>
  );
};

export const WagtailEmbed = ({ block }) => (
  <div className="w-full flex items-center justify-center">
    <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
      <div className="relative block w-full rounded-lg overflow-hidden focus:outline-none focus:ring">
        <Embed
          url={block.value}
          width={450}
          height={300}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </div>
  </div>
);

export const WagtailBlock = ({ block, ctas, images }) => {
  const blocks = parsePython(block?.rawValue || "{}");

  return (
    <div>
      {blocks?.map((b, i) => (
        <Content key={i} block={b} ctas={ctas} images={images} />
      ))}
    </div>
  );
};

export const Content = ({ block, ctas, images }) => (
  <>
    {(block.field === "text" || block.type === "text") && (
      <WagtailText block={block} images={images} />
    )}
    {(block.field === "faq" || block.type === "faq") && (
      <WagtailFaq block={block} images={images} />
    )}
    {(block.field === "image" || block.type === "image") && (
      <WagtailImage block={block} images={images} />
    )}
    {(block.field === "embed" || block.type === "embed") && (
      <WagtailEmbed block={block} />
    )}
    {(block.field === "cta" || block.type === "cta") && (
      <WagtailCTA block={block} ctas={ctas} />
    )}
    {(block.field === "table" || block.type === "table") && (
      <WagtailTable block={block} />
    )}
    {(block.field === "blocks" || block.type === "blocks") && (
      <WagtailBlock block={block} ctas={ctas} images={images} />
    )}
  </>
);

export const Column = ({ section, ctas, images, gridOverride }) => {
  const [config, content] = section.blocks;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [columns] = config.blocks;
  console.log(gridOverride || columns.value);
  return (
    <div
      className={
        {
          "no-grid": "beep",
          "2": "lg:grid lg:grid-cols-2 lg:gap-8",
          "3": "lg:grid lg:grid-cols-3 lg:gap-8",
          "4": "lg:grid lg:grid-cols-4 lg:gap-8",
        }[gridOverride || columns.value] || "max-w-7xl mx-auto"
      }
    >
      {content.blocks.map((block, i) => (
        <Content key={i} block={block} ctas={ctas} images={images} />
      ))}
    </div>
  );
};

export const Wagtail = ({
  sections,
  snippets,
  images,
  gridOverride = null,
  proseOverride = null,
}) => {
  const ctas = snippets
    ?.filter((s) =>
      ["Wagtail_CallToAction", "CallToAction"].includes(s.__typename)
    )
    .reduce((acc, s) => {
      acc[s.id] = s;
      acc[s.name] = s;
      return acc;
    }, {});
  const imgs = images?.reduce((acc, s) => {
    acc[s.id] = s;
    return acc;
  }, {});
  return (
    <Prose proseOverride={proseOverride}>
      {sections?.map((s, i) => (
        <Column
          key={i}
          section={s}
          ctas={ctas}
          images={imgs}
          gridOverride={gridOverride}
        />
      ))}
    </Prose>
  );
};

export const SectionFragment = gql`
  fragment SectionFragment on SectionBlock {
    field
    ... on SectionBlock {
      blocks {
        ... on SectionSettingsBlock {
          field
          blocks {
            ... on StreamFieldBlock {
              field
              value
            }
          }
        }
        ... on SectionContentBlock {
          field
          blocks {
            field
            ... on RichTextBlock {
              value
            }
            ... on CharBlock {
              value
            }
            ... on ContentBlock {
              rawValue
            }
            ... on ImageBlock {
              blocks {
                field
                ... on TextBlock {
                  rawValue
                }
                ... on BooleanBlock {
                  value
                }
                ... on ImageChooserBlock {
                  image {
                    title
                    src
                  }
                }
              }
            }
            ... on CTABlock {
              blocks {
                blockType
                field
                rawValue
              }
            }
            ... on StreamFieldBlock {
              value
            }
          }
        }
      }
    }
  }
`;

export const WagtailSectionFragment = graphql`
  fragment Wagtail_SectionFragment on Wagtail_SectionBlock {
    field
    ... on Wagtail_SectionBlock {
      blocks {
        ... on Wagtail_SectionSettingsBlock {
          field
          blocks {
            ... on Wagtail_StreamFieldBlock {
              field
              value
            }
          }
        }
        ... on Wagtail_SectionContentBlock {
          field
          blocks {
            field
            ... on Wagtail_RichTextBlock {
              value
            }
            ... on Wagtail_CharBlock {
              value
            }
            ... on Wagtail_ContentBlock {
              rawValue
            }
            ... on Wagtail_ImageBlock {
              blocks {
                field
                ... on Wagtail_TextBlock {
                  rawValue
                }
                ... on Wagtail_BooleanBlock {
                  value
                }
                ... on Wagtail_ImageChooserBlock {
                  image {
                    title
                    src
                  }
                }
              }
            }
            ... on Wagtail_CTABlock {
              blocks {
                blockType
                field
                rawValue
              }
            }
            ... on Wagtail_StreamFieldBlock {
              value
            }
          }
        }
      }
    }
  }
`;
