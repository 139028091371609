import * as React from "react";
import { Link } from "gatsby";

import { Button } from "./Button";

export const Primary = ({ url, children }) => (
  <Button
    variant="cta"
    size={4}
    style={{ color: "white", textDecoration: "none" }}
    {...(url.startsWith("http") || url.startsWith("mailto")
      ? {
          as: "a",
          target: "_blank",
          rel: "noreferrer noopener",
          href: url,
        }
      : {
          as: Link,
          to: url,
        })}
  >
    {children}
  </Button>
);

export const Secondary = ({ url, children }) => (
  <Button
    size={4}
    style={{ color: "#0f418c", textDecoration: "none" }}
    {...(url.startsWith("http")
      ? {
          as: "a",
          target: "_blank",
          rel: "noreferrer noopener",
          href: url,
        }
      : {
          as: Link,
          to: url,
        })}
  >
    {children}
  </Button>
);

export const Back = ({ to, label }) => (
  <>
    <nav title="Breadcrumbs" className="sm:hidden">
      <Link
        to={to}
        className="text-gray-700 hover:text-gray-900 focus:outline-none focus:underline transition duration-150 ease-in-out flex items-center"
      >
        <svg
          className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 "
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
            clipRule="evenodd"
          />
        </svg>
        Back
      </Link>
    </nav>
    <nav title="Breadcrumbs" className=" items-center hidden sm:flex">
      <Link
        to={"/"}
        title="Home"
        className="text-gray-700 hover:text-gray-900 focus:outline-none focus:underline transition duration-150 ease-in-out"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="flex-shrink-0 -ml-1 h-5 w-5 "
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
          />
        </svg>
      </Link>
      <svg
        className="flex-shrink-0 mx-2 h-5 w-5 text-gray-400"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          fillRule="evenodd"
          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
          clipRule="evenodd"
        ></path>
      </svg>
      <Link
        to={to}
        className="text-gray-700 hover:text-gray-900 focus:outline-none focus:underline transition duration-150 ease-in-out"
      >
        {label}
      </Link>
    </nav>
  </>
);
