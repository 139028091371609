import * as React from "react";

import { Wagtail } from "./Wagtail";
import { Smallcaps } from "./Prose";
import { Image } from "./Image";

export const PageHeader = ({ page, snippets, images }) => (
  <div className="relative bg-gray-100">
    <div className="h-56 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
      {!!page?.headerImage && (
        <Image
          className="w-full h-full object-cover"
          src={page?.headerImage.src}
          alt={page?.headerImage.title}
          width={800}
        />
      )}
    </div>
    <div className="relative max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
      <div className="md:ml-auto md:w-1/2 md:pl-10">
        <Smallcaps>{page?.subheader}</Smallcaps>
        <h1 className="mt-2 mb-6 text-3xl leading-9 font-semibold tracking-tight sm:text-4xl sm:leading-10">
          {page?.header}
        </h1>
        <Wagtail
          sections={page?.headerText}
          snippets={snippets}
          images={images}
        />
      </div>
    </div>
  </div>
);

export const SearchPageHeader = ({ page, snippets, images }) => {
  return (
    <div className="border-b border-gray-200 px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center sm:justify-between py-4 sm:py-6">
        <div className="flex-1 min-w-0">
          <h1 className="font-jaf text-4xl leading-6 text-gray-900sm:truncate">
            {page?.header}
          </h1>
        </div>
        <Wagtail
          sections={[page?.headerText[1]]}
          snippets={snippets}
          images={images}
          gridOverride={"no-grid"}
          proseOverride={"no-prose"}
        />
      </div>

      <Wagtail
        sections={[page.headerText[0]]}
        snippets={snippets}
        images={images}
        proseOverride={"text-base text-gray-700"}
      />
      <div className="h-4 sm:h-6" />
    </div>
  );
};
